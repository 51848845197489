import React, { useState } from 'react';
import useCocktails from '../hooks/useCocktails';
import CocktailCardMenu from './CocktailCardMenu';
import CocktailDetailsMenu from './CocktailDetailsMenu';
import useIngredients from '../hooks/useIngredients'; // Importiere Hook für Zutaten

interface CocktailGridProps {
  selectedIngredients: string[];
  setSelectedIngredients: (ingredients: string[]) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;  // New prop to set search term
}

const CocktailGridMenu: React.FC<CocktailGridProps> = ({ selectedIngredients, setSelectedIngredients, selectedTags, setSelectedTags, searchTerm, setSearchTerm }) => {
  const { cocktails, loading, error, loadCocktails } = useCocktails();
  const { ingredients } = useIngredients(); // Lade die Zutaten
  const [selectedCocktail, setSelectedCocktail] = useState<any | null>(null);
  const [isMixing, setIsMixing] = useState(false); // Zustand für das Mixen
  const [isRefreshing, setIsRefreshing] = useState(false); // Zustand für das Neuladen

  // Funktion, um zu prüfen, ob ein Cocktail den Suchkriterien entspricht
  const matchesSearchTerm = (cocktail: any) => {
    const searchTerms = searchTerm.toLowerCase().split(' ');

    return searchTerms.every(term => 
      cocktail.name.toLowerCase().includes(term) ||
      cocktail.tags.toLowerCase().includes(term) ||
      cocktail.description.toLowerCase().includes(term) ||
      cocktail.ingredients.some((ingredient: any) => ingredient.name.toLowerCase().includes(term))
    );
  };

  // Filtere Cocktails basierend auf den ausgewählten Zutaten, Tags und dem Suchbegriff
  const filteredCocktails = cocktails.filter(cocktail =>
    cocktail.ingredients
      .filter(ingredient => ingredient.order_number >= 1 && ingredient.order_number <= 99)
      .every(ingredient => selectedIngredients.includes(ingredient.name)) &&
    selectedTags.every(tag => cocktail.tags.includes(tag)) &&
    matchesSearchTerm(cocktail)
  );

  const handleCardClick = (cocktail: any) => {
    if (cocktail.availability) {
      setSelectedCocktail(cocktail);
    }
  };

  const resetFiltersAndSearch = () => {
    setSelectedIngredients(ingredients.map(ingredient => ingredient.name)); // Setze alle Zutaten als ausgewählt
    setSelectedTags([]);
    setSearchTerm(''); // Reset search term
  };

  const closeDetailsModal = () => {
    setSelectedCocktail(null);
  };

  const closeMixingModal = async () => {
    setIsMixing(false);
    setIsRefreshing(true); // Setze den Zustand für das Neuladen
    await loadCocktails(); // Cocktails nach dem Schließen des Modals erneut laden
    resetFiltersAndSearch(); // Filter und Suchbegriff zurücksetzen
    setIsRefreshing(false); // Beende den Zustand für das Neuladen
  };

  if (loading && !isRefreshing) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (filteredCocktails.length === 0) {
    return <p>No cocktails match the selected ingredients or search term.</p>;
  }

  return (
    <div>
      <div className={`grid grid-cols-2 gap-10 ${isRefreshing ? 'opacity-50' : ''}`}>
        {filteredCocktails.map(cocktail => (
          <div key={cocktail.id} onClick={() => handleCardClick(cocktail)}>
            <CocktailCardMenu cocktail={cocktail} />
          </div>
        ))}
      </div>

      {selectedCocktail && (
        <CocktailDetailsMenu
          cocktail={selectedCocktail}
          onClose={closeDetailsModal}
        />
      )}
    </div>
  );
};

export default CocktailGridMenu;
