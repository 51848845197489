import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

interface FilterByTagsProps {
  availableTags: string[];
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterByTags: React.FC<FilterByTagsProps> = ({ availableTags, selectedTags, setSelectedTags }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleTagClick = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Ensure "alkoholfrei" is always the first tag
  const sortedTags = ['alkoholfrei', ...availableTags.filter(tag => tag !== 'alkoholfrei')];

  return (
    <div className="mb-4">
      <h2 className="cursor-pointer text-xl flex items-center" onClick={toggleOpen} style={{ color: 'black' }}>
        Tags:
        <span className="ml-2" style={{ color: 'black' }}>
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      </h2>
      {isOpen && (
        <div className="mt-2 flex flex-wrap">
          {sortedTags.map(tag => (
            <div
              key={tag}
              onClick={() => handleTagClick(tag)}
              className={`m-1 px-2 py-1 text-s rounded-full cursor-pointer ${
                selectedTags.includes(tag) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              {tag}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterByTags;
