import React, { useState, useEffect } from 'react';
import FilterByIngredients from '../components/FilterByIngredients';
import FilterByTags from '../components/FilterByTags';
import SearchBarMenu from '../components/SearchBarMenu';
import CocktailGridMenu from '../components/CocktailGridMenu';
import useCocktails from '../hooks/useCocktails';
import useIngredients from '../hooks/useIngredients';
import CocktailDetailsMenu from '../components/CocktailDetailsMenu';

const CocktailMenu: React.FC = () => {
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { availableTags, cocktails } = useCocktails();
  const { ingredients } = useIngredients();
  const [selectedCocktail, setSelectedCocktail] = useState<any | null>(null);

  useEffect(() => {
    setSelectedIngredients(ingredients.map(ingredient => ingredient.name));
  }, [ingredients]);

  const handleBingoClick = () => {
    const randomCocktail = cocktails[Math.floor(Math.random() * cocktails.length)];
    setSelectedCocktail(randomCocktail);
  };

  const closeDialog = () => {
    setSelectedCocktail(null);
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-4xl font-bold text-center mt-4">Cocktailkarte</h1>
      <SearchBarMenu searchTerm={searchTerm} setSearchTerm={setSearchTerm} onBingoClick={handleBingoClick} />
      <div className="flex flex-col sm:flex-row mt-4">
        <div className="flex-1 sm:p-4 sm:border-r">
          <FilterByTags availableTags={availableTags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
          <FilterByIngredients selectedIngredients={selectedIngredients} setSelectedIngredients={setSelectedIngredients} />
        </div>
        <div className="flex-1 sm:p-4">
          <CocktailGridMenu
            selectedIngredients={selectedIngredients}
            setSelectedIngredients={setSelectedIngredients}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      </div>
      {selectedCocktail && (
        <div className="fixed inset-0 z-50">
          <CocktailDetailsMenu
            cocktail={selectedCocktail}
            onClose={closeDialog}
          />
        </div>
      )}
    </div>
  );
};

export default CocktailMenu;
