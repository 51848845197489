import React, { useState, useEffect } from 'react';
import { fetchBottles } from '../api/apiService';
import './CocktailDetails.css';

interface Ingredient {
  name: string;
  amount: number | null;
  order_number: number;
}

interface Bottle {
  id: number;
  name: string;
  alcoholic: boolean;
  calibration_factor: number;
  current_amount: number;
  port: number;
  created_at: string;
  updated_at: string;
}

interface CocktailDetailsProps {
  cocktail: {
    id: number;
    name: string;
    description: string;
    is_alcoholic: boolean;
    availability: boolean;
    ingredients: Ingredient[];
    alcohol_content: number;
  };
  onClose: () => void;
}

const CocktailDetailsMenu: React.FC<CocktailDetailsProps> = ({ cocktail, onClose }) => {
  const [bottles, setBottles] = useState<Bottle[]>([]);

  useEffect(() => {
    const fetchBottleData = async () => {
      try {
        const response = await fetchBottles();
        setBottles(response.bottles);
      } catch (error) {
        console.error('Error fetching bottles:', error);
      }
    };

    fetchBottleData();
  }, []);

  const imagePath = `/${cocktail.name
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]/g, '_')
    .replace(/_+/g, '_')
  }.jpg`;

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/backup.jpg'; // Pfad zum Backup-Bild
  };

  const filteredIngredients = cocktail.ingredients.filter(ingredient => 
    ingredient.order_number >= 1 && ingredient.order_number <= 99
  );

  const ingredientsWithCl = filteredIngredients.map(ingredient => ({
    ...ingredient,
    amount: ingredient.amount ? Math.round((ingredient.amount / 100) * 20) : null
  }));

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
        <div className="relative w-full max-w-md h-full bg-white p-4 flex flex-col overflow-hidden">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-3xl font-bold w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center"
          >
            ✖
          </button>
          <div className="flex flex-col h-full overflow-y-auto mt-12">
            <div className="text-center">
              <div className="text-4xl font-bold mb-4">
                {cocktail.name}
              </div>
            </div>
            <div className="relative w-full mb-4" style={{ paddingBottom: '100%' }}>
              <img
                src={imagePath}
                alt={cocktail.name}
                className="absolute w-full h-full rounded-lg"
                onError={handleImageError}
              />
            </div>
            <div className="flex-grow">
              <h3 className="text-2xl font-semibold pb-2">Beschreibung:</h3>
              <p className="pb-2">{cocktail.description}</p>
              <h3 className="text-2xl font-semibold pb-2">Zutaten:</h3>
              <ul className="list-none">
                {ingredientsWithCl.map((ingredient, index) => (
                  <li key={index} className="mb-2">
                    <span className="inline-block text-left font-bold pr-2">{ingredient.amount} cl</span>
                    {ingredient.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CocktailDetailsMenu;
