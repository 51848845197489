import { useEffect, useState } from 'react';
import { fetchCocktails } from '../api/apiService';

interface Ingredient {
  id: number;
  name: string;
  amount: number | null;
  alcoholic: boolean | null;
  order_number: number;
}

interface Cocktail {
  id: number;
  name: string;
  description: string;
  is_alcoholic: boolean;
  ingredients: Ingredient[];
  tags: string;
  alcohol_content: number;
  sweetness: number;
  created_at: string;
  updated_at: string;
  availability: boolean;
}

const useCocktails = () => {
  const [cocktails, setCocktails] = useState<Cocktail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [availableTags, setAvailableTags] = useState<string[]>([]);

  const loadCocktails = async () => {
    try {
      setLoading(true);
      const response = await fetchCocktails();
      setCocktails(response);
      const tags = new Set<string>();
      response.forEach(cocktail => {
        cocktail.tags.split(', ').forEach(tag => tags.add(tag));
      });
      setAvailableTags(Array.from(tags));
    } catch (err) {
      setError('Error fetching cocktails');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCocktails();
  }, []);

  return { cocktails, loading, error, availableTags, loadCocktails, setCocktails };
};

export default useCocktails;
