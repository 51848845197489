import axios from 'axios';

//const API_BASE_URL = 'http://192.168.94.142:5000';
//const API_BASE_URL = 'http://192.168.8.100:5000';
const API_BASE_URL = 'https://cocktails-backend.taeger.online'
const API_KEY = 'key'; // Setze deinen API-Schlüssel hier ein

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': API_KEY,
  },
});

interface DeleteEventResponse {
  message: string;
}

export const cleanAllPumps = async (): Promise<string> => {
  const response = await apiClient.post<{ message: string }>('/configuration/clean_all');
  return response.data.message;
};

export const deleteEvent = async (eventId: number): Promise<DeleteEventResponse> => {
  const response = await apiClient.delete<DeleteEventResponse>(`/events/delete/${eventId}`);
  return response.data;
};

interface TagResponse {
  tags: string[];
}

interface NextPortResponse {
  next_port: number;
}


interface AlltimeEventResponse extends EventData {
  mixed_drinks: (MixedDrink & { is_alcoholic: boolean })[];
}


interface UpdateEventRequest {
  average_age?: number;
  cost?: number;
  date?: string;
  id: number;
  name: string;
  number_of_cocktails?: number;
  number_of_guests?: number;
}

interface IngredientResponse {
  bottles: {
    id: number;
    name: string;
    alcoholic: boolean;
    calibration_factor: number;
    current_amount: number;
    port: number;
    created_at: string;
    updated_at: string;
  }[];
}

export interface Ingredient {
  id: number;
  name: string;
  amount: number | null;
  order_number: number;
  alcoholic: boolean;
  cocktail_id: number;
  created_at: string;
  updated_at: string;
}

export interface Cocktail {
  id: number;
  name: string;
  description: string;
  alcohol_content: number;
  sweetness: number;
  tags: string;
  ingredients: Ingredient[];
  is_alcoholic: boolean;
  is_doable: boolean;
  availability: boolean;
  created_at: string;
  updated_at: string;
}

interface CompatibleIngredientResponse {
  compatible_ingredients: {
    id: number;
    name: string;
  }[];
}

interface UpdateVolumeRequest {
  bottle_name: string;
  bottle_volume: number;
}

interface UpdateVolumeResponse {
  volume: string;
}

interface CreateBottleRequest {
  current_amount: number;
  name: string;
  port: number;
}

interface User {
  first_name: string;
  last_name: string;
  age: number;
  alcohol_allowed: boolean;
  nfc_serial: string;
}

export const fetchUsers = async () => {
  const response = await apiClient.get('/users/all');
  return response.data;
};

export const fetchUser = async (userId: number) => {
  const response = await apiClient.get(`/users/${userId}`);
  return response.data;
};

export const createUser = async (user: User) => {
  const response = await apiClient.post('/users/create', user);
  return response.data;
};

export const updateUser = async (userId: number, user: User) => {
  const response = await apiClient.put(`/users/${userId}`, user);
  return response.data;
};

export const deleteUser = async (userId: number) => {
  const response = await apiClient.delete(`/users/${userId}`);
  return response.data;
};

export const fetchNfcSerial = async (): Promise<string> => {
  const response = await apiClient.get('/users/next-nfc-serial');
  return response.data.nfc_serial;
};


interface CreateBottleResponse {
  bottle: {
    alcoholic: boolean;
    calibration_factor: number;
    created_at: string;
    current_amount: number;
    id: number;
    name: string;
    port: number;
    updated_at: string;
  };
  message: string;
}

interface UpdateBottleRequest {
  id?: number;
  alcoholic: boolean;
  current_amount: number;
  name: string;
  port?: number;
}

interface UpdateBottleResponse {
  bottle: {
    alcoholic: boolean;
    calibration_factor: number;
    created_at: string;
    current_amount: number;
    id: number;
    name: string;
    port: number;
    updated_at: string;
  };
  message: string;
}

interface DeleteBottleResponse {
  message: string;
}

interface MixedDrink {
  name: string;
  total_count: number;
  is_alcoholic: boolean; // Add this property
}

interface EventData {
  average_age: number;
  event_id: number;
  event_date: string; // Use event_date here as it matches the API response
  event_name: string;
  ingredients: {
    name: string;
    total_amount: number;
    total_cost: number;
  }[];
  mixed_drinks: {
    name: string;
    total_count: number;
    is_alcoholic: boolean;
  }[];
  number_of_cocktails: number;
  number_of_guests: number;
  total_cost: number;
}


interface Event {
  event_id: number;
  event_name: string;
}

export const createCocktail = async (cocktail: Cocktail): Promise<void> => {
  const response = await apiClient.post('/cocktails/create', {
    name: cocktail.name,
    description: cocktail.description,
    alcohol_content: cocktail.alcohol_content,
    sweetness: cocktail.sweetness,
    tags: cocktail.tags,
    is_alcoholic: cocktail.is_alcoholic,
    is_doable: cocktail.is_doable,
    availability: cocktail.availability,
    ingredients: cocktail.ingredients.map(ingredient => ({
      name: ingredient.name,
      amount: ingredient.amount,
      order_number: ingredient.order_number,
      alcoholic: ingredient.alcoholic
    }))
  });
  return response.data;
};

export const deleteCocktail = async (id: number): Promise<{ message: string }> => {
  const response = await apiClient.delete<{ message: string }>(`/cocktails/delete/${id}`);
  return response.data;
};

export const calibrateIngredient = async (ingredientName: string, duration: number): Promise<{ message: string }> => {
  const response = await apiClient.post<{ message: string }>('/ingredients/calibrate', {
    ingredient_name: ingredientName,
    duration
  });
  return response.data;
};

export const updateCocktail = async (cocktail: Cocktail): Promise<void> => {
  const response = await apiClient.put(`/cocktails/update/${cocktail.id}`, {
    name: cocktail.name,
    description: cocktail.description,
    alcohol_content: cocktail.alcohol_content,
    sweetness: cocktail.sweetness,
    tags: cocktail.tags,
    is_alcoholic: cocktail.is_alcoholic,
    is_doable: cocktail.is_doable,
    availability: cocktail.availability,
    ingredients: cocktail.ingredients.map(ingredient => ({
      name: ingredient.name,
      amount: ingredient.amount,
      order_number: ingredient.order_number,
      alcoholic: ingredient.alcoholic
    }))
  });
  return response.data;
};

export const tareScale = async (): Promise<{ message: string }> => {
  const response = await apiClient.post<{ message: string }>('/scale/tare');
  return response.data;
};


export const calibrateEmptyGlass = async (): Promise<{ GLASS_WEIGHT: number }> => {
  const response = await apiClient.post<{ GLASS_WEIGHT: number }>('/configuration/calibrate_empty_glass');
  return response.data;
};

export const getNewMaxVolume = async (): Promise<{ MAX_VOLUME: number }> => {
  const response = await apiClient.post<{ MAX_VOLUME: number }>('/configuration/new_max_volume');
  return response.data;
};


export const fetchTags = async (): Promise<TagResponse> => {
  const response = await apiClient.get<TagResponse>('/cocktails/tags');
  return response.data;
};

export const fetchIngredients = async (): Promise<IngredientResponse> => {
  const response = await apiClient.get<IngredientResponse>('/bottles/all');
  return response.data;
};

export const fetchCocktails = async (): Promise<Cocktail[]> => {
  const response = await apiClient.get<Cocktail[]>('/cocktails/doable');
  return response.data;
};

export const fetchAllCocktails = async (): Promise<Cocktail[]> => {
  const response = await apiClient.get<Cocktail[]>('/cocktails/all');
  return response.data;
};

export const mixCocktail = async (id: number, strength: number): Promise<any> => {
  const response = await apiClient.post('/cocktails/mix', { id, strength });
  return response.data;
};

export const fetchBottles = async (): Promise<IngredientResponse> => {
  const response = await apiClient.get<IngredientResponse>('/bottles/all');
  return response.data;
};

export const fetchCompatibleIngredients = async (): Promise<CompatibleIngredientResponse> => {
  const response = await apiClient.get<CompatibleIngredientResponse>('/ingredients/compatible');
  return response.data;
};

export const updateBottleVolume = async (data: UpdateVolumeRequest): Promise<UpdateVolumeResponse> => {
  const response = await apiClient.post<UpdateVolumeResponse>('/bottles/update_volume', data);
  return response.data;
};

export const fetchNextPort = async (): Promise<NextPortResponse> => {
  const response = await apiClient.get<NextPortResponse>('/bottles/next-port');
  return response.data;
};

export const createBottle = async (data: CreateBottleRequest): Promise<CreateBottleResponse> => {
  const response = await apiClient.post<CreateBottleResponse>('/bottles/add', data);
  return response.data;
};

export const updateBottle = async (data: UpdateBottleRequest): Promise<UpdateBottleResponse> => {
  const response = await apiClient.put<UpdateBottleResponse>('/bottles/update', data);
  return response.data;
};

export const deleteBottle = async (id: number): Promise<DeleteBottleResponse> => {
  const response = await apiClient.post<DeleteBottleResponse>(`/bottles/delete/id/${id}`);
  return response.data;
};

export const primeAllPumps = async (): Promise<string> => {
  const response = await apiClient.post<{ message: string }>('/bottles/prime_all');
  return response.data.message;
};

export const primeSinglePump = async (bottleId: number): Promise<string> => {
  const response = await apiClient.post<{ message: string }>(`/bottles/prime/${bottleId}`);
  return response.data.message;
};

export const fetchEventInfo = async (eventId: number): Promise<EventData> => {
  const response = await apiClient.get<EventData>(`/events/info/${eventId}`);
  return response.data;
};

export const fetchEvents = async (): Promise<Event[]> => {
  const response = await apiClient.get<Event[]>('/events/all');
  return response.data;
};

export const createEvent = async (data: any) => {
  const response = await apiClient.post('/events/create', data);
  return response.data;
};

export const updateEvent = async (data: UpdateEventRequest): Promise<void> => {
  await apiClient.put('/events/update', data);
};

export const fetchConfig = async (key: string) => {
  const response = await apiClient.get(`/configuration/${key}`);
  return response.data[key];
};

export const updateConfig = async (key: string, value: any) => {
  const response = await apiClient.post(`/configuration/${key}`, { value });
  return response.data[key];
};

export const fetchAlltimeEvent = async (): Promise<AlltimeEventResponse> => {
  const response = await apiClient.get<AlltimeEventResponse>('/events/all_info');
  return response.data;
};

