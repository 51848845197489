import { useEffect, useState } from 'react';
import { fetchIngredients } from '../api/apiService';

const useIngredients = () => {
  const [ingredients, setIngredients] = useState<{ id: number; name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getIngredients = async () => {
      try {
        const response = await fetchIngredients();
        setIngredients(response.bottles.map(bottle => ({ id: bottle.id, name: bottle.name })));
      } catch (err) {
        setError('Error fetching ingredients');
      } finally {
        setLoading(false);
      }
    };

    getIngredients();
  }, []);

  return { ingredients, loading, error };
};

export default useIngredients;
