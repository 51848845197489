import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MaintenanceLayout from './layouts/MaintenanceLayout';
import MaintenanceLayoutUser from './layouts/MaintenanceLayoutUser';
import HomePage from './pages/HomePage';
import PasswordProtectedRoute from './components/PasswordProtectedRoute'; // Import der Passwortschutz-Komponente
import CocktailMenu from './pages/CocktailMenu';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<CocktailMenu />} />

      </Routes>
    </Router>
  );
};

export default App;
