import React, { useState, useEffect } from 'react';
import { fetchIngredients } from '../api/apiService';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

interface FilterByIngredientsProps {
  selectedIngredients: string[];
  setSelectedIngredients: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterByIngredients: React.FC<FilterByIngredientsProps> = ({ selectedIngredients, setSelectedIngredients }) => {
  const [ingredients, setIngredients] = useState<{ id: number; name: string }[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getIngredients = async () => {
      try {
        const response = await fetchIngredients();
        const ingredientData = response.bottles.map(bottle => ({ id: bottle.id, name: bottle.name }));
        setIngredients(ingredientData);
        setSelectedIngredients(ingredientData.map(ingredient => ingredient.name)); // Alle Zutaten standardmäßig auswählen
      } catch (error) {
        console.error('Error fetching ingredients:', error);
      }
    };

    getIngredients();
  }, [setSelectedIngredients]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleIngredientClick = (name: string) => {
    if (selectedIngredients.includes(name)) {
      setSelectedIngredients(selectedIngredients.filter(i => i !== name));
    } else {
      setSelectedIngredients([...selectedIngredients, name]);
    }
  };

  return (
    <div className="mb-4">
      <h2 className="cursor-pointer text-xl flex items-center" onClick={toggleOpen} style={{ color: 'black' }}>
        Zutaten abwählen:
        <span className="ml-2" style={{ color: 'black' }}>
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      </h2>
      {isOpen && (
        <div className="mt-2 flex flex-wrap">
          {ingredients.map(ingredient => (
            <div
              key={ingredient.id}
              onClick={() => handleIngredientClick(ingredient.name)}
              className={`m-1 px-2 py-1 text-s rounded-full cursor-pointer ${
                selectedIngredients.includes(ingredient.name) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              {ingredient.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterByIngredients;
