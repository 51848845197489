import React, { useState, useEffect } from 'react';

interface CocktailCardProps {
  cocktail: {
    id: number;
    name: string;
    description: string;
    is_alcoholic: boolean;
    availability: boolean;
  };
}

const CocktailCardMenu: React.FC<CocktailCardProps> = ({ cocktail }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const loadImage = () => {
      const imagePath = `/${cocktail.name
        .toLowerCase()
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]/g, '_')
        .replace(/_+/g, '_')
      }.jpg`;

      const img = new Image();
      img.src = imagePath;

      img.onload = () => {
        setImageSrc(imagePath);
      };

      img.onerror = () => {
        setImageSrc('/backup.jpg');
      };
    };

    loadImage();
  }, [cocktail.name]);

  return (
    <div className={`relative border rounded-lg overflow-hidden ${cocktail.availability ? 'cursor-pointer' : 'cursor-not-allowed'} ${!cocktail.availability ? 'opacity-50' : ''} flex flex-col h-full`}>
      <div className="aspect-w-1 aspect-h-1">
        <img
          src={imageSrc}
          alt={cocktail.name}
          className="object-cover w-full h-full"
        />
        <div className="absolute top-0 right-0 m-2 p-1 bg-white bg-opacity-75 rounded text-xs font-bold">
          {cocktail.is_alcoholic ? 'alkoholisch' : 'alkoholfrei'}
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col">
        <h3 className="text-lg font-bold">{cocktail.name}</h3>
        <p className="text-gray-700 flex-grow">{cocktail.description}</p>
      </div>
    </div>
  );
};

export default CocktailCardMenu;
