import React from 'react';

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  onBingoClick: () => void; // Add a new prop for the BINGO button click handler
}

const SearchBarMenu: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm, onBingoClick }) => {
  return (
    <div className="top-0 w-full bg-white p-4 shadow flex items-center">
      <input
        type="text"
        className="flex-grow p-2 border border-gray-300 rounded"
        placeholder="Suche nach Cocktails, etc."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchBarMenu;
